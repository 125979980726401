import React, { Component } from 'react';
import '../App.css';

class Header extends Component {
  render() {
    return (
      <div className="header-content">
        <h1>Site Metrics</h1>
      </div>
    );
  }
}

export default Header;